/* ==================== */
/* メニュー */
/* ==================== */
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import { USER_TYPE } from '../../const/Enum';

import './Menu.css';

const Menu = ({ onClickCallback}) => {
  const [role, setRole] = useState('');
  const roleFromCookie = Cookies.get('role');

  useEffect(() => {
    setRole(roleFromCookie);
  }, [roleFromCookie]);

  const handleClick = (event) => {
    if (onClickCallback) {
      onClickCallback(event);
    }
  };

  switch (Number(role)) {
    case USER_TYPE.OWNER:
      return (
        <div className="menu">
          <NavLink
            to="/customer-master"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>顧客マスタ
          </NavLink>
          <NavLink
            to="/admin-master"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>管理者マスタ
          </NavLink>
          <NavLink
            to="/job-master"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>研修マスタ
          </NavLink>
          <NavLink
            to="/e-signature-master"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>電子サインマスタ
          </NavLink>
          <NavLink
            to="/notification-setting"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>通知設定
          </NavLink>
          <NavLink
            to="/account-setting"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>アカウント設定
          </NavLink>
          <NavLink
            to="/terms-of-service"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>利用規約
          </NavLink>
        </div>
      )
    case USER_TYPE.ADMIN:
      return (
        <div className="menu">
          <NavLink
            to="/job-setting"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>研修設定
          </NavLink>
          <NavLink
            to="/e-signature-list"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>電子サイン一覧
          </NavLink>
          <NavLink
            to="/staff-setting"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>スタッフ設定
          </NavLink>
          <NavLink
            to="/staff-group-setting"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>グループ設定
          </NavLink>
          <NavLink
            to="/notification-setting"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>通知設定
          </NavLink>
          <NavLink
            to="/account-setting"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>アカウント設定
          </NavLink>
          <NavLink
            to="/terms-of-service"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>利用規約
          </NavLink>
        </div>
      );
    case USER_TYPE.STAFF:
      return (
        <div className="menu">
          <NavLink
            to="/job-list"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>研修一覧
          </NavLink>
          <NavLink
            to="/account-setting"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>アカウント設定
          </NavLink>
          <NavLink
            to="/terms-of-service"
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
            onClick={handleClick}>利用規約
          </NavLink>
        </div>
      );
    default:
      return null;
  }
}

export default Menu;
