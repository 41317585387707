import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Menu from './components/Menu/Menu';
import { ErrorMessage } from './const/Constant';
import AccountSetting from './pages/AccountSetting/AccountSetting';
import AdminMaster from './pages/AdminMaster/AdminMaster';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import CustomerMaster from './pages/CustomerMaster/CustomerMaster';
import ESignatureList from './pages/ESignatureList/ESignatureList';
import ESignatureMaster from './pages/ESignatureMaster/ESignatureMaster';
import JobList from './pages/JobList/JobList';
import JobMaster from './pages/JobMaster/JobMaster';
import JobSetting from './pages/JobSetting/JobSetting';
import Login from './pages/Login/Login';
import MyPage from './pages/MyPage/MyPage';
import Notification from './pages/Notification/Notification';
import NotificationSetting from './pages/NotificationSetting/NotificationSetting';
import StaffGroupSetting from './pages/StaffGroupSetting/StaffGroupSetting';
import StaffSetting from './pages/StaffSetting/StaffSetting';
import TermsOfService from './pages/TermsOfService/TermsOfService';
import { UnreadCountProvider } from './context/UnreadCountContext';
import Maintenance from './pages/Maintenance/Maintenance';

import './global.css';
import './App.css';

const App = () => {
  const [isMaintenanceMode, setMaintenanceMode] = useState(false);

  const checkMaintenanceMode = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/checkMaintenance`;
      const response = await axios.post(url);
      if (response.data.isMaintenance) {
        setMaintenanceMode(true);
      }
    } catch (error) {
      //メンテナンスモード確認でエラーが発生した場合
      alert(ErrorMessage.OUT_OF_SERVICE);
    }
  };
  
  useEffect(() => {
    checkMaintenanceMode();
  }, []);

  if (isMaintenanceMode) {
    return <Maintenance />;
  }
  
  return (
    <UnreadCountProvider>
      <Router>
        <Header />
        <AppContent />
      </Router>
    </UnreadCountProvider>
  );
};

const AppContent = () => {
  return (
    <div className="app-container">
      <Routes>
        {/* ログイン */}
        <Route path="/" element={
          <div className="content-container">
            <Login />
          </div>
        } />
        {/* アカウント作成 */}
        <Route path="/createAccount" element={
          <div className="content-container">
            <CreateAccount />
          </div>
        } />
        {/* 顧客マスタ（CLASSIX用） */}
        <Route path="/customer-master" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <CustomerMaster />
            </div>
          </div>
        } />
        {/* 管理者マスタ（CLASSIX者用） */}
        <Route path="/admin-master" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <AdminMaster />
            </div>
          </div>
        } />
        {/* 研修マスタ（CLASSIX者用） */}
        <Route path="/job-master" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <JobMaster />
            </div>
          </div>
        } />
        {/* 研修設定（管理者用） */}
        <Route path="/job-setting" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <JobSetting />
            </div>
          </div>
        } />
        {/* 電子サインマスタ */}
        <Route path="/e-signature-master" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <ESignatureMaster />
            </div>
          </div>
        } />
        {/* 電子サイン一覧 */}
        <Route path="/e-signature-list" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <ESignatureList />
            </div>
          </div>
        } />
        {/* グループ設定（管理者用） */}
        <Route path="/staff-group-setting" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <StaffGroupSetting />
            </div>
          </div>
        } />
        {/* スタッフ設定（管理者用） */}
        <Route path="/staff-setting" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <StaffSetting />
            </div>
          </div>
        } />
        {/* 研修一覧（スタッフ用） */}
        <Route path="/job-list" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <JobList />
            </div>
          </div>
        } />
        {/* 通知設定（管理者） */}
        <Route path="/notification-setting" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <NotificationSetting />
            </div>
          </div>
        } />
        {/* アカウント設定 */}
        <Route path="/account-setting" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <AccountSetting />
            </div>
          </div>
        } />
        {/* マイページ */}
        <Route path="/mypage" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <MyPage />
            </div>
          </div>
        } />
        {/* 通知画面 */}
        <Route path="/notification" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <Notification />
            </div>
          </div>
        } />
        {/* 利用規約 */}
        <Route path="/terms-of-service" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <TermsOfService />
            </div>
          </div>
        } />
      </Routes>
    </div>
  )
}

export default App;